import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'surveys.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'surveys.hasVisitedRoot',
        { name: 'surveys.about' },
        { name: 'surveys.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'surveys.about',
    path: '/about',
    meta: {
      title: 'About',
      ninja: {
        name: 'home_surveys',
      },
    },
  },
  {
    name: 'surveys.list',
    path: '/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      ninja: {
        name: 'survey_list',
      },
    },
  },
  {
    name: 'surveys.details',
    path: '/details/:id',
    props: parseProps(['id']),
    redirect: { name: 'surveys.details.report' },
    meta: {
      title: 'Survey details',
    },

    children: [
      {
        name: 'surveys.details.report',
        path: 'report',
        props: parseProps(['id']),
        meta: {
          title: 'Survey report',
          ninja: {
            name: 'survey_report',
          },
        },
      },
      {
        name: 'surveys.details.changelog',
        path: 'changelog',
        props: parseProps(['id']),
        meta: {
          title: 'Survey changelog',
          ninja: {
            name: 'survey_changelog',
          },
        },
      },
    ],
  },
  {
    path: '/edit/:id',
    name: 'surveys.edit',
    props: parseProps(['id']),
    meta: {
      ninja: {
        name: 'survey_edit',
        props: to => ({ id: to.params.id }),
      },
    },
  },
  {
    path: '/new',
    name: 'surveys.new',
    meta: {
      ninja: {
        name: 'survey_create',
        props: to => ({ id: to.params.id }),
      },
    },
  },
] as ConsoleRouteDefinition[];
