import { bindAppRoutes } from '@console/router';

export default bindAppRoutes('-root-', [
  {
    name: 'root.home',
    component: () => import('../pages/DashboardPage.vue'),
  },
  {
    name: 'root.user-profile',
    component: () => import('../pages/UserProfilePage/UserProfilePage.vue'),
  },
  {
    name: 'root.global-search',
    component: () => import('../pages/GlobalSearch/GlobalSearchResultPage.vue'),
  },
]);
