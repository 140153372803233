<template>
  <div class="limited-list" :style="wrap ? '' : 'white-space: nowrap'">
    <div data-testid="no-data" v-if="!items || !items.length">
      <slot name="no-data" v-if="$slots['no-data']" />
      <template v-else>&mdash;</template>
    </div>

    <template v-else>
      <v-row :class="{ 'flex-column': column, 'd-inline-flex': !column }" class="limited-list__wrapper">
        <div
          :key="JSON.stringify(item)"
          class="shrink pr-1 pb-1"
          data-testid="item"
          @click="$emit('item:click', item)"
          v-for="item in visible"
        >
          <slot name="item" :item="item" v-if="$slots.item" />
          <v-chip size="small" class="limited-list__item" :link="link" v-else> {{ item }}</v-chip>
        </div>
      </v-row>

      <v-tooltip :location="column ? 'left' : 'top'" color="surface-variant" max-width="300" v-if="hidden.length > 0">
        <template #activator="{ props }">
          <span data-testid="more">
            <slot name="more" :length="hidden.length" :total="items.length" v-bind="props" :on-click="onExpand" v-if="$slots.more" />

            <v-chip size="x-small" class="limited-list__item limited-list__item--more" v-bind="props" v-else> + {{ hidden.length }}</v-chip>
          </span>
        </template>

        <v-row class="bg-surface-variant flex-column" data-testid="tooltip-content">
          <slot name="item:tooltip" :items="hidden" v-if="$slots['item:tooltip']" />

          <template v-else>
            <v-col :key="i" class="flex-grow-0 ma-1" v-for="(item, i) in hidden">
              <slot name="item" :item="item" v-if="$slots['item']" />

              <v-chip size="small" class="limited-list__item limited-list__item--tooltip" v-else> {{ item }}</v-chip>
            </v-col>
          </template>
        </v-row>
      </v-tooltip>
    </template>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    items?: T[];
    limit?: number;
    wrap?: boolean;
    column?: boolean;
    link?: boolean;
  }>(),
  { items: () => [] as T[], wrap: false, column: false, link: false }
);

defineEmits(['item:click']);

const currentLimit = ref(props.limit || props.items.length);

const visible = computed(() => {
  if (props.items.length <= currentLimit.value) {
    return props.items;
  }
  return props.items.slice(0, currentLimit.value);
});

const hidden = computed(() => {
  if (visible.value.length !== props.items.length) {
    return props.items.slice(currentLimit.value);
  }
  return [];
});

const onExpand = (e: MouseEvent) => {
  e.stopPropagation();
  currentLimit.value = props.items.length;
};
</script>

<style lang="scss">
.limited-list {
  &__item {
    background: var(--v-on-surface-opacity-008) !important;
    color: var(--v-on-surface) !important;

    &--more {
      padding: 0 4px;
      margin-left: 4px;
    }

    &--tooltip {
      background: var(--v-on-surface) !important;
      color: var(--v-surface) !important;
    }
  }
}
</style>
