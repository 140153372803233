export enum SearchRecordType {
  Source = 'source',
  Reservoir = 'reservoir',
  Field = 'field',
  NinjaEvent = 'ninja_event',
  NinjaProperty = 'ninja_property',
  Experiment = 'experiment',
  DataProject = 'data_project',
}

export type CatalogSearchResult = {
  column_format: string | null;
  column_id: number | null;
  description: string;
  is_record_active: boolean;
  ninja_event_name: string | null;
  ninja_event_type: string | null;
  ninja_matrix_name: string | null;
  ninja_property_name: string | null;
  record_title: string;
  record_type: SearchRecordType;
  reservoir_id: number | null;
  reservoir_s3_bucket: string | null;
  score: number | null;
  source_group_id: number | null;
  source_group_name: string | null;
  source_id: number | null;
  source_name: string | null;
  source_s3_prefix: string | null;
  source_tags: string[];
  source_type: string | null;
  data_project_id: number | null;
  customer_unit_id: number | null;
  id?: string;
  name?: string;
  display_name?: string;
};

export type GlobalSearchResult = {
  active?: false;
  business_unit_key: string;
  customer_unit_id: number;
  data_project_id: number | null;
  description?: string;
  event_name?: string;
  event_type?: string;
  id: number;
  ninja_matrix?: string;
  product_id?: number;
  property_name?: string;
  record_title: string;
  record_type: SearchRecordType;
  s3_prefix?: string;
};

export type SearchResult = CatalogSearchResult & GlobalSearchResult;

export type SearchResultCount = {
  field: number;
  source_group: number;
  reservoir: number;
  ninja_event: number;
  source: number;
  ninja_property: number;
  data_project?: number;
  experiment?: number;
};

export type SearchResultCountNew = {
  columns?: number;
  reservoirs?: number;
  ninja_events?: number;
  sources?: number;
  ninja_properties?: number;
  data_projects?: number;
  experiments?: number;
};
