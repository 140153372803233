<template>
  <v-list-item
    :href="item.link"
    :target="isExternalLink ? '_blank' : '_self'"
    :data-testid="`menu-item-${item.id}`"
    :active="active"
    v-bind="$attrs"
  >
    <template #prepend v-if="item.icon && showIcon">
      <v-icon size="small">{{ item.icon }}</v-icon>
    </template>
    <v-list-item-title class="text-body-2" data-testid="text">{{ item.text }}</v-list-item-title>

    <template #append>
      <v-icon size="small" data-testid="external-icon" v-if="isExternalLink">mdi-open-in-new</v-icon>

      <v-icon size="small" data-testid="children-icon" v-else-if="item.children?.length">mdi-arrow-right</v-icon>

      <v-btn
        variant="text"
        size="x-small"
        :icon="isPinned(item.id) ? 'mdi-pin' : 'mdi-pin-outline'"
        @click.stop.prevent="togglePin(item.id)"
        v-else-if="enablePinning"
      />
      <v-icon class="item__handle" v-if="enableDragging">mdi-drag</v-icon>
    </template>
  </v-list-item>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ConsoleProduct } from '@console/types';
import { usePinnedProducts } from './usePinnedProducts';

const {
  item,
  showIcon = true,
  enableDragging = false,
  enablePinning = false,
  active = false,
} = defineProps<{
  item: ConsoleProduct;
  showIcon?: boolean;
  enableDragging?: boolean;
  enablePinning?: boolean;
  active?: boolean;
}>();

const { togglePin, isPinned } = usePinnedProducts();

const isExternalLink = computed(() => {
  return item.link?.match(/^https?:\/\//);
});
</script>
