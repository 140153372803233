import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'sources.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'sources.hasVisitedRoot',
        { name: 'sources.about' },
        { name: 'sources.list', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'sources.about',
    path: '/about',
    meta: {
      title: 'About',
      ninja: {
        name: 'home_sources',
      },
    },
  },
  {
    name: 'sources.list',
    path: '/list/:cuid?',
    props: parseProps(['cuid']),
    meta: {
      title: 'List',
      ninja: {
        name: 'sources_list',
      },
    },
  },
  {
    name: 'sources.data-collection.create-hydra-stream',
    path: '/data-collection/create-hydra-stream',
    meta: {
      title: 'Create Hydra stream',
      ninja: {
        name: 'sources_create_hydra_stream',
      },
    },
  },
  {
    name: 'sources.field-details',
    path: '/-/:sourceId/fields/:fieldId',
    redirect: { name: 'sources.field-details.details' },
    props: parseProps(['sourceId', 'fieldId']),
    meta: {
      title: 'Field details',
    },
    children: [
      {
        path: 'details',
        name: 'sources.field-details.details',
        redirect: { name: 'sources.field-details.subscriptions' },
        meta: {
          title: 'Field details',
        },
        children: [
          {
            path: 'subscriptions',
            name: 'sources.field-details.subscriptions',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Subscriptions',
              ninja: {
                name: 'sources_field_details_subscriptions',
              },
            },
          },
          {
            path: 'changelog',
            name: 'sources.field-details.changelog',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Changelog',
              ninja: {
                name: 'sources_field_details_changelog',
              },
            },
          },
          {
            path: 'referenced-by',
            name: 'sources.field-details.referenced-by',
            props: parseProps(['sourceId', 'fieldId']),
            meta: {
              title: 'Referenced by',
              ninja: {
                name: 'sources_field_details_referenced_by',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/-/',
    redirect: to => {
      if (!to.query.s3Prefix) {
        return { name: 'sources.list', query: {} };
      }
      return { name: 'sources.source-details' };
    },
  },
  {
    name: 'sources.source-details',
    path: '/-/:sourceId?',
    props: route => {
      return { ...parseProps(['sourceId'])(route), s3Prefix: route.redirectedFrom?.query.s3Prefix };
    },
    meta: {
      title: 'Source details',
    },
    redirect: { name: 'sources.source-details.fields' },
    children: [
      {
        name: 'sources.source-details.fields',
        path: 'fields',
        props: parseProps(['sourceId']),
        meta: {
          title: 'Source details | Fields',
          ninja: {
            name: 'sources_details_fields',
          },
        },
      },
      {
        name: 'sources.source-details.details',
        path: 'details',
        props: parseProps(['sourceId']),
        meta: {
          title: 'Source details',
        },
        redirect: { name: 'sources.source-details.subscriptions' },
        children: [
          {
            name: 'sources.source-details.subscriptions',
            path: 'subscriptions',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Subscriptions',
              ninja: {
                name: 'sources_details_subscriptions',
              },
            },
          },
          {
            name: 'sources.source-details.hydra-details',
            path: 'hydra-details',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Hydra details',
              ninja: {
                name: 'sources_details_hydra',
              },
            },
          },
          {
            name: 'sources.source-details.lazarus-details',
            path: 'lazarus-details',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Lazarus details',
              ninja: {
                name: 'sources_details_lazarus',
              },
            },
          },
          {
            name: 'sources.source-details.changelog',
            path: 'changelog',
            props: parseProps(['sourceId']),
            meta: {
              title: 'Source details | Changelog',
              ninja: {
                name: 'sources_details_changelog',
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'sources.offline-documentation.management',
    path: '/bulk-documentation/:cuid?',
    props: parseProps(['cuid']),
    meta: {
      title: 'Sources | Bulk Documentation',
      ninja: {
        name: 'sources_bulk_documentation_management',
      },
    },
  },
] as ConsoleRouteDefinition[];
