import type { ConsoleRouteDefinition } from '../types';
import { parseProps } from '../utils';

export default [
  {
    path: '/',
    redirect: '/releases',
  },
  {
    name: 'releases.about',
    path: '/releases',
    meta: {
      title: 'releases',
      ninja: {
        name: 'home_news_releases',
      },
    },
  },
  {
    name: 'releases.release-details',
    path: '/release/:id',
    redirect: { name: 'releases.release-details.view' },
    props: parseProps(['id']),
    meta: {
      title: 'Release details',
    },
    children: [
      {
        name: 'releases.release-details.view',
        path: '',
        props: parseProps(['id']),
        meta: {
          title: 'Release details',
          ninja: {
            name: 'news_details',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
      {
        name: 'releases.release-details.edit',
        path: 'edit',
        props: parseProps(['id']),
        meta: {
          title: 'Edit release',
          ninja: {
            name: 'create_news',
            props: to => ({ resource_id: to.params.id }),
          },
        },
      },
    ],
  },
] as ConsoleRouteDefinition[];
