import { computed } from 'vue';
import { useSettingsManager } from '@console/composables';
import { useProducts } from '@console/composables/useProducts';
import { STORAGE_KEYS } from '@console/constants';
import { ConsoleProduct } from '@console/types';

const { getProduct } = useProducts();
const pinnedProductIds = useSettingsManager<string[]>(STORAGE_KEYS.MENU_PINNED_MODULES, []);

const pinnedProducts = computed<ConsoleProduct[]>(() => pinnedProductIds.value.map(id => getProduct(id)).filter(product => product));

const togglePin = (productId: string) => {
  const index = pinnedProductIds.value.indexOf(productId);
  if (index === -1) {
    pinnedProductIds.value.push(productId);
  } else {
    pinnedProductIds.value.splice(index, 1);
  }
};

const isPinned = (productId: string) => {
  return pinnedProductIds.value.includes(productId);
};

const onPinnedReorder = (products: ConsoleProduct[]) => {
  pinnedProductIds.value = products.map(product => product.id);
};

export function usePinnedProducts() {
  return {
    isPinned,
    onPinnedReorder,
    pinnedProductIds,
    pinnedProducts,
    togglePin,
  };
}
