import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute } from '../utils';

export default [
  {
    name: 'global-permissions.root',
    path: '/',
    redirect: () => {
      return getLandingRoute(
        'global-permissions.hasVisitedRoot',
        { name: 'global-permissions.about' },
        { name: 'global-permissions.manage' }
      );
    },
  },
  {
    name: 'global-permissions.about',
    path: '/about',
    meta: {
      title: 'Global permissions | About',
      ninja: {
        name: 'global-permissions',
      },
    },
  },
  {
    name: 'global-permissions.manage',
    path: '/manage',
    meta: {
      title: 'Global permissions | Manage',
      ninja: {
        name: 'global-permissions_manage',
      },
    },
    children: [
      {
        name: 'global-permissions.manage.dpo',
        path: '/manage/dpo',
      },
      {
        name: 'global-permissions.manage.releases-owner',
        path: '/manage/releases-owner',
      },
      {
        name: 'global-permissions.manage.ambassador',
        path: '/manage/ambassador',
      },
      {
        name: 'global-permissions.manage.analyst',
        path: '/manage/analyst',
      },
      {
        name: 'global-permissions.manage.user-behaviour-owner',
        path: '/manage/user-behaviour-owner',
      },
      {
        name: 'global-permissions.manage.on-call',
        path: '/manage/on-call',
      },
      {
        name: 'global-permissions.manage.researcher',
        path: '/manage/researcher',
      },
      {
        name: 'global-permissions.manage.lazarus-admin',
        path: '/manage/lazarus-admin',
      },
    ],
  },
] as ConsoleRouteDefinition[];
