import { CustomerUnit } from '../data-projects';

export enum GlobalPermissionType {
  DPO = 'dpo',
  Ambassador = 'ambassador',
  Analyst = 'analyst',
  ReleasesOwner = 'editor',
  OnCall = 'on_call',
  UserBehaviourOwner = 'user_behaviour_owner',
  Researcher = 'researcher',
  LazarusAdmin = 'lazarus-admin',
}

// Used for response of GET list endpoint
export type GlobalPermissionAssignmentItem = {
  id: number;
  identity: string;
  policy: GlobalPermissionType;
  scope: string;
  business_unit?: { description: string; key: string; name: string };
  customer_unit?: Pick<CustomerUnit, 'business_unit_key' | 'description' | 'id' | 'name' | 'products'>;
  all_units?: GlobalPermissionAssignmentItem['customer_unit' | 'business_unit'][];
};

// Used for creation assignments
export type GlobalPermissionAssignments = {
  policy_key: GlobalPermissionType;
  customer_unit_id?: number;
  business_unit_key?: string;
  users: string[];
};

export type GlobalPermissionAssignmentPayload = {
  policy_key: GlobalPermissionType;
  customer_unit_id?: number;
  business_unit_key?: string;
  identity: string;
};

export type GlobalPermissionAuthInfo = {
  available_policies: { key: string; name: string; scope: string }[];
  independent_actions: {
    can_create_bu: boolean;
    can_assign_dpo: boolean;
    can_assign_news_editor: boolean;
    can_assign_ambassador: boolean;
    can_assign_oncall: boolean;
    can_assign_user_behaviour_owner: boolean;
    can_assign_researcher: boolean;
    can_assign_lazarus_admin: boolean;
  };
};
