<template>
  <v-menu
    location="bottom right"
    attach="#console"
    :close-on-content-click="false"
    :content-class="`console-menu__menu ${removeBorderRightRadius ? 'console-menu__menu--ignore-right-radius' : ''}`"
    class="console-menu"
    data-testid="console-menu"
    scroll-strategy="block"
    v-model="menuLevel1"
  >
    <template #activator="{ props, isActive }">
      <v-btn
        data-testid="activator"
        icon="mdi-apps"
        class="console-menu__activator text-on-black"
        :class="{
          'v-btn--active': isActive,
        }"
        v-bind="props"
      />
    </template>

    <div data-testid="content" class="console-menu__content bg-inverse-surface elevation-2 text-neutral">
      <div ref="menuLevel1El" class="content__wrapper layout">
        <v-text-field
          clearable
          hide-details
          variant="outlined"
          density="compact"
          label="Search modules"
          class="mt-3 mx-2"
          prepend-inner-icon="mdi-magnify"
          data-testid="search-input"
          @input="onSearchInput"
          v-model="searchTerm"
        />

        <v-list rounded class="menu--level1 v-list--inversed">
          <div class="menu__item-group" v-if="searchTerm">
            <template v-if="searchResults.length">
              <ConsoleMenuItem
                :key="item.text"
                :item="item"
                enable-pinning
                data-testid="search-result-item"
                v-for="item in searchResults"
              />
            </template>
            <v-list-item v-else>No matching modules found</v-list-item>
          </div>

          <template v-else>
            <div class="menu__item-group" v-if="pinnedProducts.length">
              <draggable
                handle=".item__handle"
                animation="150"
                item-key="id"
                class="menu__item-group--pinned bg-inverse-surface"
                :list="pinnedProducts"
                @change="onPinnedReorder(pinnedProducts)"
              >
                <template #item="{ element }">
                  <ConsoleMenuItem
                    :key="element.id"
                    :item="element"
                    enable-pinning
                    enable-dragging
                    class="menu-pinned-item"
                    data-testid="menu-pinned-item"
                  />
                </template>
              </draggable>
            </div>

            <div class="menu__item-group">
              <ConsoleMenuItem
                :key="item.text"
                :item="item"
                :show-icon="false"
                data-testid="menu-helper-item"
                @mouseenter="onHover(item)"
                v-for="item in availableHelperItems"
              />
            </div>

            <div class="menu__item-group">
              <v-list-item :href="requestsToReviewLink" data-testid="menu-item" @mouseenter="onHover()">
                <template #prepend><v-icon size="small">mdi-bell-outline</v-icon></template>
                <v-list-item-title class="text-body-2">Notification centre</v-list-item-title>

                <template #append v-if="notificationsCount">
                  <div class="text-caption-1 mr-1">{{ notificationsCountText }}</div>
                </template>
              </v-list-item>
            </div>

            <div class="menu__item-group">
              <ConsoleMenuItem
                :key="item.text"
                :item="item"
                :active="selectedItem?.text === item.text"
                @mouseenter="onHover(item, $event)"
                v-for="item in availableProducts"
              />
            </div>

            <v-menu
              attach="#console"
              :content-class="`console-menu__menu ${removeBorderLeftRadius ? 'console-menu__menu--ignore-left-radius' : ''}`"
              class="console-menu console-menu__level2"
              data-testid="console-menu"
              :style="`top: ${offsetTop}px; left: ${offsetLeft}px`"
              persistent
              v-model="menuLevel2"
            >
              <div class="console-menu__content elevation-2 text-neutral bg-inverse-surface">
                <div ref="menuLevel2El" data-testid="content-level2" class="content__wrapper layout" v-if="menuLevel2">
                  <v-list rounded class="menu--level2 v-list--inversed">
                    <ConsoleMenuItem :key="item.text" :item="item" enable-pinning v-for="item in selectedItem?.children" />
                  </v-list>
                </div>
              </div>
            </v-menu>
          </template>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';
import { useProducts } from '@console/composables/useProducts';
import { getAppRouteHref } from '@console/router';
import { ConsoleProduct } from '@console/types';
import { useElementBounding, useElementVisibility, useMouseInElement } from '@vueuse/core';
import ConsoleMenuItem from './ConsoleMenuItem.vue';
import { usePinnedProducts } from './usePinnedProducts';

/**
 * Create a polygon shape, which adds extra 5 pixels to the rectangle defined by `width` and `maxHeight` but this extra space
 * is skipped on the top left side up to `offset` pixels down
 */
const getPolygonShape = (width: number, offset: number, maxHeight = 1000) => {
  const points = [
    '0px -5px',
    `${width + 5}px -5px`,
    `${width + 5}px ${maxHeight}px`,
    `-5px ${maxHeight}px`,
    `-5px ${offset}px`,
    `0px ${offset}px`,
  ];
  return `polygon(${points.join(', ')})`;
};

const props = defineProps<{ notificationsCount: number }>();

const searchTerm = ref('');
const { availableHelperItems, availableProducts } = useProducts();
const { pinnedProducts, onPinnedReorder } = usePinnedProducts();
const selectedItem = ref<ConsoleProduct>(null);

const menuLevel1 = ref(false);
const menuLevel2 = ref(false);
const menuLevel1El = ref<HTMLElement>();
const menuLevel2El = ref<HTMLElement>();
const menuLevel1ElBounding = useElementBounding(menuLevel1El);
const isMenuLevel2Rendered = useElementVisibility(menuLevel2El);

const offsetLeft = computed(() => {
  return menuLevel1ElBounding.right.value;
});

const areBottomEdgesEqual = ref(false);
const removeBorderLeftRadius = ref(false);
const removeBorderRightRadius = computed(() => {
  return menuLevel2.value && (!removeBorderLeftRadius.value || areBottomEdgesEqual.value);
});

const offsetTop = ref(0);
const menuLevel2Neighbourhood = ref('');
const onHover = async (item?: ConsoleProduct, event?: Event) => {
  const children = item?.children;
  if (children) {
    if (event?.target) {
      const values = useElementBounding(event?.target as HTMLElement);
      const listItemHeight = values.height.value;

      const paddingTop = window.getComputedStyle(menuLevel1El.value.children[0]).getPropertyValue('padding-top');
      const paddingNumber = Number(paddingTop.replace('px', '')) || 8;
      offsetTop.value = values.top.value - paddingNumber;
      const bottomEdge = offsetTop.value + children.length * listItemHeight + 2 * paddingNumber;

      // if the bottom edge (plus 16 pixels to have some margin for better look) is below the visible window move it a bit higher
      if (bottomEdge + 16 > window.innerHeight) {
        const diff = bottomEdge + 16 - window.innerHeight;
        offsetTop.value -= Math.ceil(diff / listItemHeight) * listItemHeight;
      }

      // checks if the menu2 bottom edge is above bottom edge of menu1
      removeBorderLeftRadius.value = bottomEdge <= menuLevel1ElBounding.bottom.value;
      areBottomEdgesEqual.value = bottomEdge === menuLevel1ElBounding.bottom.value;

      // we are defining the shape where we allow the box-shadow of menu2 to be displayed
      // `menuLevel1ElBounding.bottom.value - offsetTop.value` is the length of the common edge between menu1
      // and menu2 and this segment shouldn't have the elevation
      menuLevel2Neighbourhood.value = getPolygonShape(
        menuLevel1ElBounding.width.value,
        menuLevel1ElBounding.bottom.value - offsetTop.value
      );
    }
    selectedItem.value = item;
    menuLevel2.value = true;
  } else {
    menuLevel2.value = false;
    selectedItem.value = null;
  }
};

const notificationsCountText = computed(() => {
  return props.notificationsCount > 100 ? '100+' : props.notificationsCount;
});

const requestsToReviewLink = computed(() => {
  return getAppRouteHref('notification-center', {
    name: 'notification-center.requests-to-review',
  });
});

const searchResults = computed(() => {
  if (!searchTerm.value) {
    return [];
  }
  const normalisedSearchTerm = searchTerm.value.toLowerCase();

  const productNameMatches = [];
  const keywordMatches = [];
  availableProducts.value.forEach(category => {
    category.children?.forEach(product => {
      const textMatch = product.text.toLowerCase().includes(normalisedSearchTerm);
      const keywordMatch = product.keywords?.some(keyword => keyword.toLowerCase().includes(normalisedSearchTerm));

      if (textMatch) {
        productNameMatches.push(product);
      } else if (keywordMatch) {
        keywordMatches.push(product);
      }
    });
  });

  return [...productNameMatches, ...keywordMatches];
});

const onSearchInput = () => {
  menuLevel2.value = false;
  selectedItem.value = null;
};

watch(menuLevel1, () => {
  selectedItem.value = null;
});

const { isOutside: isOutsideMenuLevel1 } = useMouseInElement(menuLevel1El);
const { isOutside: isOutsideMenuLevel2 } = useMouseInElement(menuLevel2El);

const isOutside = computed(() => {
  return isOutsideMenuLevel1.value && isOutsideMenuLevel2.value;
});

watch(isOutside, () => {
  setTimeout(() => {
    if (isOutside.value) {
      menuLevel2.value = false;
      selectedItem.value = null;
    }
  }, 100);
});

// There is default box-shadow of menu2 which is overlapping the menu1,
// we are using the clipPath and the polygon shape where we "cut off" the shadow on the left edge where menu2 touches menu1
watch([isMenuLevel2Rendered, menuLevel2Neighbourhood], value => {
  if (value && menuLevel2El.value) {
    // menuLevel2El.value.parentElement.parentElement refers the direct element created by `v-menu`, however we
    // cannot use `ref` on `v-menu`, since it's attaches to the root app element and ignores the `ref`
    menuLevel2El.value.parentElement.parentElement.style.clipPath = menuLevel2Neighbourhood.value;
  }
});
</script>

<style lang="scss">
@import '@console/theme/styles/variables';

#console .console-menu {
  $w: 280px;
  $p: 12px;

  &.console-menu__level2 {
    position: fixed !important;
  }

  &__content {
    overflow: hidden;
    width: $w;
    border-radius: $border-radius-large !important;
    background: var(--v-inverse-surface) !important;

    .content__wrapper {
      transform: translateX(0);
      transition: $basic-transition !important;

      .menu--level1,
      .menu--level2 {
        min-width: $w;
      }
    }
  }

  @at-root {
    & + .console-menu {
      .console-menu__content {
        border-top-left-radius: 0 !important;
        border-left: 1px solid var(--v-on-surface-variant);
      }
    }

    &__menu {
      border-radius: 8px !important;

      &--ignore-left-radius {
        .console-menu__content {
          border-bottom-left-radius: 0 !important;
        }
      }

      &--ignore-right-radius {
        .console-menu__content {
          border-bottom-right-radius: 0 !important;
        }
      }

      .v-list-item:not(:last-child):not(:only-child) {
        margin-bottom: 0 !important;
      }

      .v-list.v-list--inversed {
        .v-list-item.v-list-item--active {
          background: var(--v-surface2-opacity-012) !important;
          color: var(--v-on-inverse-surface) !important;
        }
      }

      .menu__item-group {
        position: relative;

        &--pinned {
          max-height: 35vh;
          overflow-x: hidden;
          overflow-y: auto;
        }

        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            left: 16px;
            bottom: 0;
            width: calc(100% - 32px);
            height: 1px;
            border-bottom: 1px solid var(--v-outline);
          }
        }
      }
    }
  }
  .menu-pinned-item {
    .item__handle {
      cursor: grab;
    }
    &.sortable-chosen,
    &.sortable-ghost,
    &.sortable-drag {
      .item__handle {
        cursor: grabbing !important;
      }
    }
  }
}
</style>
