import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout } from '@console/composables/useUser/actions';
import { impersonatedUser, token } from '@console/composables/useUser/data';
import { router } from '@console/router';

export default class HttpService {
  api = axios.create({ timeout: 30000 });

  private urlBase: string;

  constructor(urlBase: string) {
    this.urlBase = urlBase;

    // setup interceptors, use spread-syntax to perserve reference to this
    this.api.interceptors.request.use(
      (...args) => this.requestInterceptor(...args),
      (...args) => this.requestErrorInterceptor(...args)
    );
    this.api.interceptors.response.use(
      (...args) => this.responseInterceptor(...args),
      (...args) => this.responseErrorInterceptor(...args)
    );
  }

  private getURL(path: string) {
    return `${this.urlBase}${path.replace(this.urlBase, '')}`;
  }

  protected includeExtraHeaders(): { [key: string]: string } {
    return {};
  }

  requestInterceptor(request) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token.value}`,
      ...this.includeExtraHeaders(),
    };

    if (impersonatedUser.value) {
      request.headers['Impersonated-As'] = impersonatedUser.value;
    }

    return request;
  }

  requestErrorInterceptor(error: AxiosError) {
    return Promise.reject(error);
  }

  responseInterceptor(response) {
    if (response.config?.extra?.raw) {
      return response;
    }

    return this.unpack(response);
  }

  responseErrorInterceptor(error: AxiosError) {
    if (error.response?.status) {
      // const data = error.response.data || {};
      // const text = data.msg || data.message || (data.errors && data.errors[0]) || 'Error';
      // let showError = true;

      switch (error.response.status) {
        case 400:
          break;
        case 401:
        case 422: {
          logout();
          const login = router.resolve({
            name: 'login',
            query: {
              returnTo: `${window.location.pathname}${window.location.search}`,
            },
          });

          window.location.href = login.href;

          break;
        }
        case 403:
          // The token is not valid anymore
          // logout()
          break;
        case 404:
          // router.push('/404')
          break;

        case 500:
          // router.push('/500')
          break;
        default:
          break;
      }
    }

    if (error.response?.data) {
      return Promise.reject(error.response.data);
    }

    if (error.message) {
      return Promise.reject(error.message);
    }
  }

  GET<T = any>(path: string, params?: any, headers?: any) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: this.getURL(path),
      headers,
      params,
    };
    return this.api.request<void, T>(config);
  }

  POST<T = any>(path: string, params?: any, headers?: any) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: this.getURL(path),
      data: params,
      headers,
    };

    return this.api.request<void, T>(config);
  }

  PUT<T = any>(path: string, params?: any, headers?: any) {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: this.getURL(path),
      data: params,
      headers,
    };

    return this.api.request<void, T>(config);
  }

  PATCH<T = any>(path: string, params?: any, headers?: any) {
    const config: AxiosRequestConfig = {
      method: 'PATCH',
      url: this.getURL(path),
      data: params,
      headers,
    };

    return this.api.request<void, T>(config);
  }

  DELETE<T = any>(path: string, params?: any, headers?: any) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: this.getURL(path),
      params,
      headers,
    };

    return this.api.request<void, T>(config);
  }

  /**
   * Unpacks httpService response
   * @todo Check if makes sense to make this default and implement flag to skip it
   * @export
   * @param {AxiosResponse} response
   * @returns {AxiosResponse.data}
   */
  unpack<T = any>(response: AxiosResponse): T {
    return response.data as T;
  }
}
