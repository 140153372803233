import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute, parseProps } from '../utils';

export default [
  {
    name: 'user-behaviour.root',
    path: '/',
    redirect: () => {
      const { customerUnitId } = useGlobalFilters();

      return getLandingRoute(
        'user-behaviour.hasVisitedRoot',
        { name: 'user-behaviour.about' },
        { name: 'user-behaviour.matrices', params: { cuid: customerUnitId.value.toString() } }
      );
    },
  },
  {
    name: 'user-behaviour.about',
    path: '/about',
    meta: {
      title: 'User behaviour',
      ninja: {
        name: 'home_user_behaviour',
      },
    },
  },
  {
    name: 'user-behaviour.matrices',
    path: '/all-products',
    meta: {
      title: 'User behaviour | All products',
      ninja: {
        name: 'user_behaviour_products',
      },
    },
  },
  {
    name: 'user-behaviour.matrix-details',
    path: '/all-products/:matrixId',
    props: route => {
      return {
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Product details',
    },
    redirect: { name: 'user-behaviour.matrix-details.events' },
    children: [
      {
        name: 'user-behaviour.matrix-details.events',
        path: 'events',
        meta: {
          title: 'Product details | Events',
          ninja: {
            name: 'user_behaviour_product_events',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.properties',
        path: 'properties',
        meta: {
          title: 'Product details | Properties',
          ninja: {
            name: 'user_behaviour_product_properties',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.releases',
        path: 'releases',
        meta: {
          title: 'Product details | Releases',
          ninja: {
            name: 'user_behaviour_product_releases',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.unreleased-changes',
        path: 'unreleased-changes',
        meta: {
          title: 'Product details | Unreleased changes',
          ninja: {
            name: 'user_behaviour_product_unreleased_changes',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.matrix-details.statistics',
        path: 'statistics',
        meta: {
          title: 'Product details | Statistics',
          ninja: {
            name: 'user_behaviour_product_statistics',
          },
        },
        props: route => {
          return {
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },

  {
    name: 'user-behaviour.event-details',
    path: '/all-products/:matrixId/events/:eventId',
    props: route => {
      return {
        eventId: route.params.eventId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Event details',
    },
    redirect: { name: 'user-behaviour.event-details.trackers' },
    children: [
      {
        name: 'user-behaviour.event-details.trackers',
        path: 'trackers',
        meta: {
          title: 'Event details | Trackers',
          ninja: {
            name: 'user_behaviour_event_details_trackers',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.properties',
        path: 'properties',
        meta: {
          title: 'Event details | Properties',
          ninja: {
            name: 'user_behaviour_event_details_properties',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.history',
        path: 'history',
        meta: {
          title: 'Event details | History',
          ninja: {
            name: 'user_behaviour_event_details_history',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.event-details.statistics',
        path: 'statistics',
        meta: {
          title: 'Event details | Statistics',
          ninja: {
            name: 'user_behaviour_event_details_statistics',
          },
        },
        props: route => {
          return {
            eventId: route.params.eventId,
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },

  {
    name: 'user-behaviour.property-details',
    path: '/all-products/:matrixId/properties/:propertyId',
    props: route => {
      return {
        propertyId: route.params.propertyId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Properties details',
    },
    redirect: { name: 'user-behaviour.property-details.trackers' },
    children: [
      {
        name: 'user-behaviour.property-details.trackers',
        path: 'trackers',
        meta: {
          title: 'Property details | Trackers',
          ninja: {
            name: 'user_behaviour_property_details_trackers',
          },
        },
        props: route => {
          return {
            propertyId: route.params.propertyId,
            matrixId: route.params.matrixId,
          };
        },
      },
      {
        name: 'user-behaviour.property-details.events',
        path: 'events',
        meta: {
          title: 'Property details | Events',
          ninja: {
            name: 'user_behaviour_property_details_events',
          },
        },
        props: route => {
          return {
            propertyId: route.params.propertyId,
            matrixId: route.params.matrixId,
          };
        },
      },
    ],
  },
  {
    name: 'user-behaviour.release-details',
    path: '/all-products/:matrixId/releases/:releaseId',
    props: route => {
      return {
        releaseId: route.params.releaseId,
        matrixId: route.params.matrixId,
      };
    },
    meta: {
      title: 'User behaviour | Release details',
      ninja: {
        name: 'user_behaviour_release_details',
      },
    },
  },
  {
    name: 'user-behaviour.create-event',
    path: '/create/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'User behaviour | Create',
      ninja: {
        name: 'user_behaviour_create',
      },
    },
  },

  // {
  //   name: 'user-behaviour.liveview-configs',
  //   path: '/create/:cuid',
  //   meta: {
  //     title: 'User behaviour | Create',
  //   },
  // },

  {
    name: 'user-behaviour.liveview-list',
    path: '/liveview/list/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'Liveview | List',
      ninja: {
        name: 'liveview_list',
      },
    },
  },
  {
    name: 'user-behaviour.liveview-create',
    path: '/liveview/new',
    meta: {
      title: 'Liveview | Create',
      ninja: {
        name: 'liveview_new_session',
      },
    },
  },
  {
    name: 'user-behaviour.liveview-details',
    path: '/liveview/:id/details',
    props: parseProps(['id']),
    meta: {
      title: 'Liveview | Details',
      ninja: {
        name: 'liveview_session',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },

  {
    name: 'user-behaviour.ninja-watch-contexts-list',
    path: '/ninja-watch/contexts/:cuid',
    props: parseProps(['cuid']),
    meta: {
      title: 'NinjaWatch | Tracking Contexts list',
      ninja: {
        name: 'ninja_watch_contexts_list',
      },
    },
  },
  {
    name: 'user-behaviour.ninja-watch-context-details',
    path: '/ninja-watch/contexts/:id',
    props: parseProps(['id']),
    meta: {
      ninja: {
        name: 'ninja_watch_contexts_details',
        props: to => ({ resource_id: to.params.id }),
      },
    },
    redirect: { name: 'user-behaviour.ninja-watch-context-details.report' },
    children: [
      {
        name: 'user-behaviour.ninja-watch-context-details.definition',
        path: 'definition',
      },
      {
        name: 'user-behaviour.ninja-watch-context-details.report',
        path: 'report',
        redirect: { name: 'user-behaviour.ninja-watch-context-details.report-statistics' },
        children: [
          {
            name: 'user-behaviour.ninja-watch-context-details.report-statistics',
            path: 'statistics',
            meta: {
              ninja: {
                name: 'ninja_watch_contexts_details_statistics',
              },
            },
          },
          {
            name: 'user-behaviour.ninja-watch-context-details.report-last24h',
            path: 'last24h',
            meta: {
              ninja: {
                name: 'ninja_watch_contexts_details_last24h_statistics',
              },
            },
          },
        ],
      },
    ],
  },
] as ConsoleRouteDefinition[];
