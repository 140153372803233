export type ConsoleProduct = {
  category?: string;
  children?: Omit<ConsoleProduct, 'children'>[];
  description?: string;
  disabled?: boolean;
  hideInMenu?: boolean;
  summary?: string;
  icon?: string;
  id: string;
  keywords?: string[];
  link?: string;
  owners?: string[];
  showOnHomepage?: boolean;
  text: string;
};

export const CONSOLE_PRODUCTS = {
  // helper items
  ALL_SERVICES: 'all-services',
  DOCUMENTATION: 'documentation',
  NEWS_AND_RELEASES: 'news-and-releases',
  NOTIFICATION_CENTER: 'notification-center',

  // categories
  API_AI_TOOLS: 'api-ai-tools',
  COLLECT_AND_CONSUME: 'collect-and-consume',
  MANAGE_ACCESS: 'manage-access',
  TEST_AND_ANALYSE: 'test-and-analyse',

  // products
  DAPI: 'dapi',
  DATA_PROJECTS: 'data-projects',
  DATA_SOURCES: 'data-sources',
  EXPERIMENTS: 'experiments',
  FEATURE_ROLLOUTS: 'feature-rollouts',
  FREJAML_MACHINE_LEARNING: 'frejaml-machine-learning',
  GENAI: 'genai-platform',
  GLOBAL_PERMISSIONS: 'global-permissions',
  LAZARUS: 'lazarus',
  METRICS_LIBRARY: 'metrics-library',
  PLAYGROUND: 'playground',
  RESERVOIRS: 'reservoirs',
  SURVEYS: 'surveys',
  USER_BEHAVIOUR: 'user-behaviour',
};
