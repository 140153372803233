import type { ConsoleRouteDefinition } from '../types';
import { getLandingRoute } from '../utils';

export default [
  {
    name: 'genai.root',
    path: '/',
    redirect: () => {
      return getLandingRoute('genai.hasVisitedRoot', { name: 'genai.about' }, { name: 'genai.applications' });
    },
  },
  {
    name: 'genai.about',
    path: '/',
    meta: {
      ninja: {
        name: 'genai_about_page',
      },
    },
  },

  {
    name: 'genai.applications',
    path: '/applications',
    meta: {
      ninja: {
        name: 'genai_applications_page',
      },
    },
  },

  // {
  //   name: 'genai.reporting',
  //   path: '/reporting',
  //   meta: {
  //     ninja: {
  //       name: 'genai_reporting_page',
  //     },
  //   },
  // },

  {
    name: 'genai.models',
    path: '/models',
    meta: {
      ninja: {
        name: 'genai_models_page',
      },
    },
  },

  {
    name: 'genai.new-application',
    path: '/application/new',
    meta: {
      ninja: {
        name: 'genai_new_application_page',
      },
    },
  },

  {
    name: 'genai.edit-application',
    path: '/application/:id/edit',
    props: true,
    meta: {
      ninja: {
        name: 'genai_edit_application_page',
        props: to => ({ resource_id: to.params.id }),
      },
    },
  },

  {
    name: 'genai.application',
    path: '/application/:id',
    props: true,
    meta: {
      hideLeftNav: true,
      ninja: {
        name: 'genai_application_page',
        props: to => ({ resource_id: to.params.id }),
      },
    },

    redirect: { name: 'genai.application.api-keys' },
    children: [
      // {
      //   name: 'genai.application.monitoring',
      //   path: 'monitoring',
      //   props: true,
      // },
      {
        name: 'genai.application.api-keys',
        path: 'api-keys',
        props: true,
      },
    ],
  },
] as ConsoleRouteDefinition[];
