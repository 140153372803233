export enum SourceType {
  Database = 'lazarus',
  UserBehaviour = 'ninja',
  Reservoir = 'reservoir-out',
  Service = 'hydra',
}

export enum SourceChangeApprovalStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
}

export enum OfflineDocumentationGeneratedFileState {
  Pending = 'pending',
  QueuedProcessing = 'queued_processing',
  Processing = 'processing',
  QueuedCreation = 'queued_creation',
  Creating = 'creating',
  Cancelled = 'cancelled',
  Applied = 'applied',
  Failed = 'failed',
}

export enum FulldumpStatus {
  New = 'new',
  Running = 'running',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export type FieldStatusCounts = {
  active: number;
  new: number;
  suspicious: number;
  unclassified: number;
  personal: number;
  personal_operational: number;
};

export type Source = {
  active: boolean;
  active_column_count: number;
  authinfo: { can_edit: boolean; can_review: boolean };
  fields_status: FieldStatusCounts;
  total_column_count: number;
  description: string;
  id: number;
  name: string;
  s3_bucket: string;
  s3_prefix: string;
  tags: string[];
  source_group?: {
    id: number;
    brand_entities: string[];
    name: string;
  };
  pending_changes: {
    item_type: string;
    author: string;
    approval_status: SourceChangeApprovalStatus;
    approval_message: string;
    approval_actor: string;
    class_name: string;
    components: { id?: number; name?: string }[]; // in some cases API can return array of empty objects
    unhealthy_after: string;
    user_id_columns: { id: number; name?: string }[];
    brand_column: { id: number; name?: string };
    country_column: { id: number; name?: string };
    name: string;
    description: string;
    tags: string[];
    active: boolean;
    brand_entities: string[];
    change_id: number;
  };
  /**
   * @deprecated
   */
  owners: string[]; // DEPRECATED! it's old ACL owners
  brand_entities?: string[];
  change_id?: number;
  source_type?: SourceType;
  components: { id?: number; name?: string }[]; // in some cases API can return array of empty objects
  user_id_columns?: { id: number; path: string }[];
  country_column: { id: number; path: string } | null;
  brand_column: { id: number; path: string } | null;
  papuga_conflict_column_count: number;
  table_path?: string;
  data_project_id: number;
  customer_unit_id: number;
  stream_name?: string;
  unhealthy_after?: string;
  class_name?: string;
  source_group_id?: number;
  has_pending_change: boolean;
  is_user_id_applicable: boolean;
  alert_indexer_noncompliance: boolean;
  ninja_region?: string;
};

export type Field = {
  pending_changes: Record<string, unknown>;
  foreign_column: string;
  foreign_source: string;
  classification: string;
  primary_key: string;
  foreign_key: string;
  name: string;
  path: string;
  type: string;
  is_default_field: boolean;
  is_unclassified: boolean;
  id: number;
  active: boolean;
  description: string;
  deleted: boolean;
  papuga_conflict: boolean;
};

export type HydraStreamPreview = {
  display_name?: string;
  message?: string;
  name?: string;
  s3_prefix?: string;
  status: string;
};

export type HydraStream = Partial<{
  id: number;
  empty_files: boolean;
  created_at: string;
  name: string;
  display_name?: string;
  s3_bucket: string;
  s3_prefix: string;
  state: 'pending' | 'ready';
  has_sessions: boolean;
  discard_headers: boolean;
  redirect: any;
  data_project_id: number;
}>;
