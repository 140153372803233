<template>
  <ConsoleApplication v-bind="$props" :left-nav-items="leftNavItems" disable-cu-selector>
    <template #main>
      <router-view />
    </template>
  </ConsoleApplication>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalSearch } from '@console/composables/useGlobalSearch';
import { getAppRoute } from '@console/router';
import { LeftNavigationItem, SearchRecordType } from '@console/types';
import { ConsoleApplication, consoleApplicationProps } from '@console/ui/ConsoleApplication';
import { useTitle } from '@vueuse/core';

export default defineComponent({
  name: 'App',
  components: {
    ConsoleApplication,
  },

  props: consoleApplicationProps,

  setup() {
    const title = useTitle();
    const route = useRoute();
    const isResultPage = computed(() => route.name === 'root.global-search');
    const globalSearch = useGlobalSearch();
    const searchLeftNavItems = computed<Record<string, LeftNavigationItem[]>>(() => ({
      'Global Search': [
        {
          enabled: () => true,
          title: 'Sources',
          icon: 'mdi-cube-outline',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.Source)),
          count: globalSearch.resultsCount.value?.source || globalSearch.newResultsCount.value?.sources,
        },
        {
          enabled: () => true,
          title: 'Fields',
          icon: 'mdi-cube-outline',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.Field)),
          count: globalSearch.resultsCount.value?.field || globalSearch.newResultsCount.value?.columns,
        },
        {
          enabled: () => true,
          title: 'Reservoirs',
          icon: 'mdi-folder-key-outline',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.Reservoir)),
          count: globalSearch.resultsCount.value?.reservoir || globalSearch.newResultsCount.value?.reservoirs,
        },
        {
          enabled: () => true,
          title: 'Events',
          icon: 'mdi-cursor-default-gesture-outline',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.NinjaEvent)),
          count: globalSearch.resultsCount.value?.ninja_event || globalSearch.newResultsCount.value?.ninja_events,
        },
        {
          enabled: () => true,
          title: 'Properties',
          icon: 'mdi-cursor-default-gesture-outline',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.NinjaProperty)),
          count: globalSearch.resultsCount.value?.ninja_property || globalSearch.newResultsCount.value?.ninja_properties,
        },
        {
          enabled: () => true,
          title: 'Data Projects',
          icon: 'mdi-select-group',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.DataProject)),
          count: globalSearch.resultsCount.value?.data_project || globalSearch.newResultsCount.value?.data_projects,
        },
        {
          enabled: () => true,
          title: 'Experiments',
          icon: 'mdi-ab-testing',
          exact: true,
          to: getAppRoute('-root-', globalSearch.getRouteParams(SearchRecordType.Experiment)),
          count: globalSearch.resultsCount.value?.experiment || globalSearch.newResultsCount.value?.experiments,
        },
      ],
    }));

    const leftNavItems = computed(() => (isResultPage.value ? searchLeftNavItems.value : undefined));

    title.value = 'Console';

    return { leftNavItems, searchLeftNavItems };
  },
});
</script>
